/* eslint-disable react/no-danger */
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout/layout';
import sitting from '../../images/headerNewsroom.svg';
import SocialIcons from '../../components/social-icons/social-icons';

import { btnUnderlineHover } from './newsdetail.module.css';

function NewsDetail({ data, location }) {
  const headerContent = {
    heading: 'Newsroom',
    subheading:
      'The world of Federal Health IT is constantly evolving. Staying in the know provides an advantage. Find the latest Federal Health news here.',
    image: sitting,
    alt: 'Reading the News',
  };

  return (
    <Layout title="Newsroom" headerContent={headerContent} location={location}>
      <div className="flex justify-center mobile:px-[15px]">
        <div className="mt-[60px] lg:w-[890px] xl:w-[1198px]">
          <div
            className={`
            ${btnUnderlineHover}
            inline-block
            text-orange
            lg:text-18
            font-roboto
            font-normal
            mobile:mx-[25px]
          `}
          >
            <Link to="/newsroom"> &lt;&lt; Go Back</Link>
          </div>
          <div className="mt-[30px]">
            <div className="relative grid lg:grid-cols-2 grid-cols-1">
              <div className="flex">
                <span className="text-primary font-bold lg:text-[40px] text-32 mobile:mx-[25px] sm:w-[90%]">
                  {data.article.title}
                </span>
                <span className="lg:absolute right-0 lg:top-0 lg:pt-3 mobile:right-6 mobile:top-2">
                  <SocialIcons
                    title={data?.article?.title}
                    pageLink={`https://www.hive.gov/news/detail/${data?.article?.id}`}
                  />
                </span>
              </div>
            </div>
            <p className="lg:text-[24px] text-[17px] text-neutral-400 font-normal mobile:mx-[25px]">
              {data.article.publishDate}
            </p>
          </div>

          <div className="mt-[30px] leading-[35px] mobile:leading-[30px]">
            <div>
              <div
                className="text-justify sm:ml-[25px] lg:ml-[unset] lg:text-[24px] text-[17px] text-neutral-400 lg:pl-[25px]"
                dangerouslySetInnerHTML={{
                  __html: data.article.body.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
          {data?.article?.image?.file?.url && (
            <img
              src={data.article.image.file.url}
              alt={data.article.image.description}
              className="lg:mt-[118px] xl:mt-[110px] mt-[80px] lg:mb-[190px] mb-[54px]"
            />
          )}
          {!data?.article?.image?.file?.url && (
            <div className="lg:mt-[195] mt-[140px]" />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default NewsDetail;

export const pageQuery = graphql`
  query ($id: String) {
    article: contentfulNewsroomArticle(id: { eq: $id }) {
      id
      body {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString: "MM/DD/YYYY")
      title
    }
  }
`;
